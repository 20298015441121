<template>
  <div class="container-fluid">
    <HeaderNav></HeaderNav>
    <div class="container container-main-app">
      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav';
export default {
  components: { HeaderNav },
};
</script>
