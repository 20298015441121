import { createStore } from 'vuex';
import { User } from '@/entities/user';
import VuexPersistence from 'vuex-persist';
import { XeroAccess } from '@/entities/xeroAccess';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export interface StoreState {
  auth: XeroAccess | null;
  userData: User | null;
}

export default createStore({
  actions: {},
  getters: {},
  state: {} as StoreState,
  modules: {},
  mutations: {
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_USER(state, userInfo) {
      state.userData = userInfo;
    },
    LOGOUT(state) {
      state.auth = null;
      state.userData = null;
    },
  },
  plugins: [vuexLocal.plugin],
});
