<template>
  <a class="btn btn-lg btn-primary-inz">
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'LargeButtonAnchor',
  props: {
    text: String,
  },
};
</script>

<style scoped></style>
