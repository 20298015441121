<template>
  <div id="pre-nav">&nbsp;</div>
  <nav id="nav" class="navbar navbar-expand-sm navbar-light bg-white">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <svg width="27" height="41" viewBox="0 0 27 41" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5927 8.78545C11.0708 8.78545 9.83972 7.53862 9.83972 6.00103C9.83972 4.46345 11.0708 3.21661 12.5927 3.21661C14.112 3.21661 15.3457 4.46345 15.3457 6.00103C15.3457 7.53862 14.112 8.78545 12.5927 8.78545Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M8.04791 2.83943C7.27257 2.83943 6.64392 2.20291 6.64392 1.41971C6.64392 0.636513 7.27257 0 8.04791 0C8.82326 0 9.45192 0.636513 9.45192 1.41971C9.45454 2.20291 8.82326 2.83943 8.04791 2.83943Z"
            fill="black"
          />
          <path
            d="M18.0986 8.78546C16.5767 8.78546 15.3456 10.0323 15.3456 11.5699C15.3456 13.1075 16.5767 14.3543 18.0986 14.3543C19.6178 14.3543 20.8515 13.1075 20.8515 11.5699C20.8515 10.0323 19.6178 8.78546 18.0986 8.78546Z"
            fill="black"
          />
          <path
            d="M23.6046 19.9467C22.0827 19.9467 20.8516 18.6999 20.8516 17.1623C20.8516 15.6247 22.0827 14.3779 23.6046 14.3779C25.1239 14.3779 26.3576 15.6247 26.3576 17.1623C26.3576 18.6999 25.1239 19.9467 23.6046 19.9467Z"
            fill="black"
          />
          <path
            d="M18.0986 19.9467C16.5767 19.9467 15.3456 21.1936 15.3456 22.7311C15.3456 24.2687 16.5767 25.5156 18.0986 25.5156C19.6178 25.5156 20.8515 24.2687 20.8515 22.7311C20.8515 21.1936 19.6178 19.9467 18.0986 19.9467Z"
            fill="black"
          />
          <path
            d="M12.5927 31.0844C11.0708 31.0844 9.83972 29.8375 9.83972 28.3C9.83972 26.7624 11.0708 25.5155 12.5927 25.5155C14.112 25.5155 15.3457 26.7624 15.3457 28.3C15.3457 29.8375 14.112 31.0844 12.5927 31.0844Z"
            fill="black"
          />
          <path
            opacity="0.35"
            d="M18.1378 29.945C17.2263 29.945 16.4876 29.1984 16.4876 28.2764C16.4876 27.3544 17.2263 26.6078 18.1378 26.6078C19.0494 26.6078 19.788 27.3544 19.788 28.2764C19.7854 29.1984 19.0468 29.945 18.1378 29.945Z"
            fill="black"
          />
          <path
            d="M7.08392 36.6532C5.56205 36.6532 4.33093 35.4064 4.33093 33.8688C4.33093 32.3312 5.56205 31.0844 7.08392 31.0844C8.60317 31.0844 9.83691 32.3312 9.83691 33.8688C9.83953 35.4064 8.60579 36.6532 7.08392 36.6532Z"
            fill="black"
          />
          <path
            opacity="0.3"
            d="M2.42421 40.1423C1.58339 40.1423 0.902344 39.4376 0.902344 38.568C0.902344 37.6984 1.58339 36.9937 2.42421 36.9937C3.26504 36.9937 3.94608 37.6984 3.94608 38.568C3.94608 39.435 3.26242 40.1423 2.42421 40.1423Z"
            fill="black"
          />
          <path
            opacity="0.35"
            d="M18.0986 15.4256C17.1477 15.4256 16.375 16.2036 16.375 17.1623C16.375 18.121 17.1451 18.8989 18.0986 18.8989C19.0494 18.8989 19.8221 18.121 19.8221 17.1623C19.8221 16.2036 19.0494 15.4256 18.0986 15.4256Z"
            fill="black"
          />
          <path
            opacity="0.35"
            d="M12.6293 35.3828C11.7885 35.3828 11.1074 34.6939 11.1074 33.8452C11.1074 32.9965 11.7885 32.3076 12.6293 32.3076C13.4701 32.3076 14.1512 32.9965 14.1512 33.8452C14.1512 34.6965 13.4701 35.3828 12.6293 35.3828Z"
            fill="black"
          />
          <path
            opacity="0.35"
            d="M23.6046 9.89868C22.6642 9.89868 21.902 10.6478 21.902 11.5699C21.902 12.4919 22.6642 13.241 23.6046 13.241C24.5449 13.241 25.3046 12.4945 25.3046 11.5699C25.3072 10.6478 24.5449 9.89868 23.6046 9.89868Z"
            fill="black"
          />
          <path
            opacity="0.35"
            d="M12.5927 13.0786C11.7676 13.0786 11.0996 12.4028 11.0996 11.5699C11.0996 10.7369 11.7676 10.0611 12.5927 10.0611C13.4152 10.0611 14.0857 10.7369 14.0857 11.5699C14.0831 12.4028 13.4152 13.0786 12.5927 13.0786Z"
            fill="black"
          />
        </svg>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarsExample03"
        aria-controls="navbarsExample03"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarsExample03">
        <ul class="navbar-nav me-auto mb-2 mb-sm-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link">About</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/why" class="nav-link">Why</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router';

export default {
  computed: {
    ...mapGetters(['auth']),
  },
  methods: {
    logout: async function () {
      this.$store.commit('LOGOUT');
      return await router.push('/login');
    },
  },
};
</script>

<style scoped>
.nav-item .nav-link {
  color: #000;
  font-weight: 500;
  margin-right: 32px;
  text-decoration: none;
}

.nav-item a:hover {
  text-decoration: underline;
}

.logout {
  cursor: pointer;
}

.nav-item .router-link-active,
.nav-item .router-link-active:hover {
  color: #e31c79;
}

#pre-nav {
  background-color: #f5f8fa;
}

#nav {
  background-color: #ffffff;
  margin: 0;
  padding: 16px 40px;
}
</style>
