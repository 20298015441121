import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/style/app.css';
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';

// Simple app
createApp(App)
  // store | vuex | redux pattern -- To maintain state
  .use(store)
  .use(router)
  .mount('#app');
