
import { defineComponent } from 'vue';
import LargeButtonAnchor from '@/components/common/LargeButtonAnchor.vue';
import router from '@/router';

export default defineComponent({
  name: 'Home',
  components: {
    LargeButtonAnchor,
  },
  methods: {
    go: () => {
      router.push('/why');
    },
  },
});
